<template>
  <div class="partsOrderBtn">
    <el-button v-if="partsOrderBtnData.productOrderStatusNum === 0" @click="handleClickTo(list, 'pay')" class="liji"
      size="small">立即付款</el-button>
    <el-button v-if="partsOrderBtnData.productOrderStatusNum === 2" @click="handleClickTo(list, 'confirmGoods')"
      size="mini">确认收货</el-button>
    <el-button v-if="partsOrderBtnData.productOrderStatusNum === 3&&isList" @click="handleClickTo(list, 'evaluation')"
      size="mini">评价</el-button>
    <el-button v-if="partsOrderBtnData.productOrderStatusNum === 0" @click="handleClickTo(list, 'cancelOrder')"
      type="text" class="buttonText" size="small">取消订单</el-button>
    <el-button v-if="partsOrderBtnData.productOrderStatusNum === 4 || partsOrderBtnData.productOrderStatusNum === 5"
      @click="handleClickTo(list, 'onceAgainToBuy')" size="mini">再次购买</el-button>
    <el-popover placement="right" width="400" popper-class="viewLogisticsPopover" trigger="click">
      <div class="viewLogisticsBtn">
        <el-timeline :reverse="reverse">
          <el-timeline-item v-for="(activity, index) in activities" :key="index" :color="activity.color"
            :timestamp="activity.acceptTime">
            {{activity.acceptStation}}
          </el-timeline-item>
        </el-timeline>
        <div v-if="activities.length <= 0">暂无物流信息</div>
      </div>
      <el-button class="buttonText" slot="reference" @click="handleClickTo(list, 'viewLogistics')"
        v-if="(partsOrderBtnData.productOrderStatusNum === 2 || partsOrderBtnData.productOrderStatusNum === 3 || partsOrderBtnData.productOrderStatusNum === 4) && (list.row && list.row.waybillNumber) && isList"
        type="text" size="small">查看物流</el-button>
    </el-popover>
    <el-button class="buttonText" v-if="isList" @click="handleClickTo(list, 'orderDetails')" type="text" size="small">
      订单详情</el-button>
    <cancel-order-modal v-if="modalVisible === 'cancelOrderVisible'"
      :dialogVisible="modalVisible === 'cancelOrderVisible'" @handleClose="handleClose" @handleConfirm="handleConfirm"
      modalType="cancelOrder" />
    <msgbox-stars-modal v-if="modalVisible === 'msgboxStartVisible'"
      :dialogVisible="modalVisible === 'msgboxStartVisible'" @handleClose="handleClose" @handleConfirm="handleConfirm"
      modalType="msgboxStart" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import CancelOrderModal from "./cancelOrderModal";
import MsgboxStarsModal from "./msgboxStarsModal";
import {
  orderCancel,
  OrderReceiving,
  OrderDetail,
  getOrderTracesByJson,
} from "@/api/sparePartsOrder";
export default {
  name: "partsOrderBtn",
  props: ["partsOrderBtnData", "isList", "list"],
  data() {
    return {
      msgboxStarsData: {},
      rowData: {},
      modalVisible: "",
      reverse: true,
      activities: [],
    };
  },
  components: {
    CancelOrderModal,
    MsgboxStarsModal,
  },
  methods: {
    onChangeTextarea(e, key) {
      this.msgboxStarsData[key] = e;
    },
    handleClose({ done }) {
      this.modalVisible = "";
    },
    handleConfirm({ form, modalType }) {
      modalType === "cancelOrder" &&
        orderCancel({
          ...form,
          id: this.partsOrderBtnData.id,
        }).then((respose) => {
          if (!this.isList) {
            this.$router.go(-1);
          }
          this.queryThen(respose, "取消订单", "0");
        });
      modalType === "msgboxStart" &&
        OrderDetail({
          ...form,
          OrderId: this.partsOrderBtnData.id,
          OrderDetailId: this.rowData.id,
          ProductId: this.rowData.productId,
        }).then((respose) => {
          this.queryThen(respose, "评价", "3");
        });
    },
    queryThen(respose, name, type) {
      if (respose.success) {
        let { result } = respose;
        this.$message({
          message: result.success ? `${name}成功` : "取消异常请联系管理员",
          type: result.success ? "success" : "warning",
        });
        this.$emit("handleConfirm", type);
      } else {
        this.$message({
          message: respose.error,
          type: "error",
        });
      }
      this.modalVisible = "";
    },
    getOrderTracesByJsonData(params) {
      getOrderTracesByJson(params).then((response) => {
        if (response.success) {
          let { result } = response;
          this.activities = result.msg.traces.map((item, index) => {
            return {
              ...item,
              color: result.msg.traces.length - 1 === index ? "#0bbd87" : "",
            };
          });
        } else {
          this.$message.error(response.error);
        }
      });
    },
    handleClickTo(list, type) {
      let url = "",
        params = {},
        goPage = true;
      if (list.row) {
        let { row } = list;
        this.rowData = row;
      }
      switch (type) {
        case "pay":
          url = "/pay/pay";
          params.orderId = this.partsOrderBtnData.id;
          break;
        case "cancelOrder":
          this.modalVisible = "cancelOrderVisible";
          goPage = false;
          break;
        case "orderDetails":
          url = "/personalCenter/orderList/details";
          params.id = this.partsOrderBtnData.id;
          break;
        case "viewLogistics":
          this.getOrderTracesByJsonData({
            orderdetailid: this.rowData.id,
          });
          break;
        case "onceAgainToBuy":
          url = "/fillInTheOrder/fillInTheOrder";
          if (list.row) {
            let { row } = list;
            params.shopList = JSON.stringify([
              {
                productId: row.productId,
                count: row.numCount,
              },
            ]);
          } else {
            params.shopList = JSON.stringify(
              list.map((row) => {
                return {
                  productId: row.productId,
                  count: row.numCount,
                };
              })
            );
          }
          break;
        case "confirmGoods":
          this.$confirm(`确认收货？`, "确认收货", {
            confirmButtonText: "提交",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              OrderReceiving({ orderId: this.partsOrderBtnData.id }).then(
                (respose) => {
                  this.queryThen(respose, "确认收货", "2");
                }
              );
              this.handleClickTo(list, "evaluation");
            })
            .catch(() => {});
          goPage = false;
          break;
        case "evaluation":
          this.modalVisible = "msgboxStartVisible";
          goPage = false;
          break;
        default:
          break;
      }
      goPage &&
        this.$router.push({
          path: url,
          query: params,
        });
    },
  },
};
</script>

<style lang="less" scoped>
.shopDetail {
  background-color: #f4f4f4;
  color: #999999;
  margin-bottom: 10px; /* no */
  padding: 10px; /* no */
  display: flex;
  justify-content: space-between;
}
.buttonText {
  color: #999999;
  display: block;
  margin: 0 auto !important;
}
.buttonText:hover {
  text-decoration: underline;
}
.partsOrderBtn {
  text-align: center;
  .liji {
    border: solid 1px #c91c25; /* no */
    color: #c91c25;
  }
  /deep/.el-dialog__body {
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
