import request from "@/utils/request";
import qs from 'qs'

export const getCancleReasonSelectList = data => {
  return request({
    url: "/Order/GetCancleReasonSelectList",
    method: "get",
    type: '1'
  });
};
export const OrderReceiving = (data) => {
  console.log(data)
  return request({
    url: "/Order/OrderReceiving",
    method: "get",
    params: data,
    type: '1'
  });
};
export const getOrderTracesByJson = (data) => {
  return request({
    url: "/Order/getOrderTracesByJson",
    method: "get",
    params: data,
    type: '1'
  });
};

export const orderCancel = data => {
  return request({
    url: "/Order/OrderCancel",
    method: "post",
    data: qs.stringify(data),
    type: "1"
  });
};

export const OrderDetail = data => {
  return request({
    url: "/Order/OrderEvaluate",
    method: "post",
    data: qs.stringify(data),
    type: "1"
  });
};