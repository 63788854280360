<template>
  <el-dialog title="评价" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
    <div class="evaluation-msgbox">
      <div class="stars" style="padding-bottom: 20px;">
        <span>整体评价</span>
        <vue-stars name="Test" v-model="EvaluateRank" value="EvaluateRank" hoverColor="#f5880c" activeColor="#f5880c" />
      </div>
      <el-input type="textarea" v-model="Content" placeholder="请填写评价">
      </el-input>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" class="handleConfirm" @click="handleConfirm">提交</el-button>
      <el-button class="handleClose" @click="handleClose">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import VueStars from "@/components/VueStars";
export default {
  name: "msgboxStarsModal",
  data() {
    return {
      Content: "",
      EvaluateRank: 5,
    };
  },
  components: {
    VueStars,
  },
  props: ["modalType", "dialogVisible"],
  methods: {
    handleClose(done, type) {
      this.Content = "";
      this.EvaluateRank = 0;
      this.$emit("handleClose", { done, modalType: this.modalType });
    },
    handleConfirm(done) {
      let reg = /[\u4e00-\u9fa5]/g;
      if (this.Content.match(reg).length.length < 8) {
        this.$message.warning("请输入有效汉字8个以上");
        return;
      }
      this.$emit("handleConfirm", {
        form: {
          Content: this.Content,
          EvaluateRank: this.EvaluateRank - 1,
        },
        modalType: this.modalType,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.evaluation-msgbox {
  text-align: left;
}
.dialog-footer {
  text-align: right;
  padding-right: 80px; /*no*/
  padding-bottom: 20px; /*no*/
  .handleConfirm {
    background: rgb(46, 196, 205);
    border-color: #dcdfe6;
    margin-right: 10px; /*no*/
  }
  /deep/.el-button:focus {
    background: #fff;
    border-color: #dcdfe6;
    color: #606266;
  }
  /deep/.el-button.handleClose:hover {
    border-color: #48d1cc;
    color: rgb(46, 196, 205);
  }
}
</style>
