<template>
  <div class="details">
    <Title />
    <div class="banner">
      <div class="line"></div>
      <h2>订单详情</h2>
    </div>
    <div class="content">
      <div class="process">
        <div class="pay">
          <div class="ordernum">
            <span class="order">订单编号：</span>
            <span class="o-num">{{ orderDetailData.orderNo }}</span>
          </div>
          <div class="wait">
            <span class="waitpay">
              {{ waitpay }}
            </span>
          </div>
          <div v-if="
              orderDetailData.countdown > 0 &&
                orderDetailData.productOrderStatusNum === 0
            " class="time">
            <span class="icon">剩余</span>
            <span class="count">{{ countdown }}</span>
          </div>
          <partsOrder-btn :list="orderDetailData.orderDetailVms" :partsOrderBtnData="orderDetailData" />
        </div>
        <div class="step">
          <ul>
            <li v-for="item in orderStatusList" :key="item.value" :class="{
                [`have${Number(item.value) + 1}`]: true,
                active: item.status
              }">
              <div :class="{ icon: true, 'el-icon-delete': '5' === item.value }"></div>
              <div class="cont">
                <span>{{ item.text || "" }}</span>
                <span>{{ item.time || "" }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="info">
        <div class="person">
          <h4>收货人信息</h4>
          <ul>
            <li>
              <span>姓&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;名：</span><span
                class="name">{{ orderDetailData.person }}</span>
            </li>
            <li>
              <span>收货地址：</span><span class="addres">{{ orderDetailData.address }}</span>
            </li>
            <li>
              <span>联系电话：</span><span class="telNum">{{ orderDetailData.phone }}</span>
            </li>
            <li>
              <span>用户留言：</span><span class="remarks">{{ orderDetailData.message }}</span>
            </li>
          </ul>
        </div>
        <div class="payInfo">
          <h4>付款信息</h4>
          <ul>
            <li>
              <span>付款方式：</span><span class="mode">{{ orderDetailData.payMethod }}</span>
            </li>
            <li>
              <span>商品金额：￥</span><span class="money">{{ orderDetailData.price }}</span>
            </li>
            <!-- <li><span>运费金额：￥</span><span class="telNum">15.00</span></li> -->
            <li>
              <span>应支付金额：￥</span><span class="remarks">{{ orderDetailData.price }}</span>
            </li>
            <li>
              <span>提取方式：</span><span class="remarks">{{ orderDetailData.pickUp }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="goods">
        <el-table :cell-style="cellStyle" :data="orderDetailData.orderDetailVms" border style="width: 100%">
          <el-table-column align="center">
            <template slot-scope="scope">
              <img class="goods-img" :src="scope.row.filePath" />
            </template>
          </el-table-column>
          <el-table-column prop="productName" label="商品" align="left"></el-table-column>
          <el-table-column prop="price" label="价格" align="center"></el-table-column>
          <el-table-column prop="numCount" label="数量" align="center">
          </el-table-column>
          <el-table-column prop="perPrice" label="小计" align="center"></el-table-column>
          <el-table-column align="center" label="操作" width="100">
            <template slot-scope="scope">
              <el-button v-if="waitpay == '待评价'" @click="handleClickTo(scope, 'evaluation')" size="mini">评价</el-button>
              <el-popover placement="right" width="400" popper-class="viewLogisticsPopover" trigger="click">
                <div class="viewLogisticsBtn">
                  <el-timeline :reverse="reverse">
                    <el-timeline-item v-for="(activity, index) in activities" :key="index" :color="activity.color"
                      :timestamp="activity.acceptTime">
                      {{ activity.acceptStation }}
                    </el-timeline-item>
                  </el-timeline>
                  <div v-if="activities.length <= 0">暂无物流信息</div>
                </div>
                <el-button class="buttonText" slot="reference" @click="handleClickTo(scope, 'viewLogistics')" v-if="
                    (orderDetailData.productOrderStatusNum === 2 ||
                      orderDetailData.productOrderStatusNum === 3 ||
                      orderDetailData.productOrderStatusNum === 4) &&
                      scope.row &&
                      scope.row.waybillNumber
                  " type="text" style="color: #999999;" size="small">查看物流</el-button>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
        <div class="goods-total">
          <ul>
            <li>
              <span class="goodsTotal-label">商品总额：</span><span
                class="goodsTotal-num">￥{{ orderDetailData.price }}</span>
            </li>
            <!-- <li>
              <span class="goodsTotal-label">优惠金额：</span><span class="goodsTotal-num">-￥{{orderDetailData.price}}</span>
            </li> -->
            <!-- <li><span>运费金额：￥</span><span class="telNum">15.00</span></li> -->
            <li class="goodsTotal-sfk">
              <span class="goodsTotal-label">实付款：</span><span class="goodsTotal-num">￥{{ orderDetailData.price }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <msgbox-stars-modal v-if="modalVisible === 'msgboxStartVisible'"
      :dialogVisible="modalVisible === 'msgboxStartVisible'" @handleClose="handleClose" @handleConfirm="handleConfirm"
      modalType="msgboxStart" />
  </div>
</template>

<script>
import partsOrderBtn from "../components/button";
import MsgboxStarsModal from "../components/msgboxStarsModal";
import { getOrderTracesByJson } from "@/api/sparePartsOrder";
import { mapState } from "vuex";
import { OrderDetail } from "@/api/sparePartsOrder";
export default {
  name: "orderListDetail",
  components: {
    MsgboxStarsModal,
    partsOrderBtn,
  },
  data() {
    return {
      active: 1,
      countdown: "",
      timer: "",
      reverse: true,
      editIsDialog: false, //编辑弹窗
      addIsDialog: false, //添加弹窗
      multipleSelection: [], //存放已选择的数据
      multipleSelection2: [],
      activities: [],
      formData: {
        name: "",
        price: "",
        number: "",
      },
      modalVisible: "",
      editData: {},
      rowData: {},
      formLabelWidth: "200",
      //表格中的数据
    };
  },
  computed: {
    ...mapState({
      orderDetailData: (state) => {
        return state.orderListDetail.orderDetailData;
      },
      orderStatusList: (state) => {
        let orderStatusListCalc = [];
        let {
          productOrderStatusNum,
          orderStatusList = [],
        } = state.orderListDetail.orderDetailData;
        if (productOrderStatusNum === 5) {
          orderStatusListCalc = [
            { ...orderStatusList.shift(), status: true },
            { ...orderStatusList.pop(), status: true },
          ];
        } else {
          orderStatusListCalc = orderStatusList.map((item, index) => {
            productOrderStatusNum >= index && (item.status = true);
            productOrderStatusNum < index && (item.status = false);
            return item;
          });
          orderStatusListCalc = orderStatusListCalc.filter(
            (item) => item.value !== "5"
          );
        }
        return orderStatusListCalc;
      },
      waitpay: (state) => {
        let { orderDetailData } = state.orderListDetail;
        if (
          orderDetailData.orderStatusList &&
          orderDetailData.orderStatusList.length > 0 &&
          typeof orderDetailData.productOrderStatusNum === "number"
        ) {
          return orderDetailData.orderStatusList[
            orderDetailData.productOrderStatusNum
          ]?.text;
        }
        return;
      },
    }),
  },
  created() {
    this.fetch();
  },
  methods: {
    handleConfirm({ form, modalType }) {
      modalType === "msgboxStart" &&
        OrderDetail({
          ...form,
          OrderId: this.orderDetailData.id,
          OrderDetailId: this.rowData.id,
          ProductId: this.rowData.productId,
        }).then((respose) => {
          this.queryThen(respose, "评价", "3");
        });
    },
    queryThen(respose, name, type) {
      if (respose.success) {
        let { result } = respose;
        this.$message({
          message: result.success ? `${name}成功` : "取消异常请联系管理员",
          type: result.success ? "success" : "warning",
        });
        this.$emit("handleConfirm", type);
      } else {
        this.$message({
          message: respose.error,
          type: "error",
        });
      }
      this.modalVisible = "";
    },
    handleClose({ done }) {
      this.modalVisible = "";
    },
    getOrderTracesByJsonData(params) {
      getOrderTracesByJson(params).then((response) => {
        if (response.success) {
          let { result } = response;
          this.activities = result.msg.traces.map((item, index) => {
            return {
              ...item,
              color: result.msg.traces.length - 1 === index ? "#0bbd87" : "",
            };
          });
        } else {
          this.$message.error(response.error);
        }
      });
    },
    handleClickTo(list, type) {
      let { row } = list;
      this.rowData = row;
      switch (type) {
        case "viewLogistics":
          this.getOrderTracesByJsonData({
            orderdetailid: row.id,
          });
          break;
        case "evaluation":
          this.modalVisible = "msgboxStartVisible";
          break;
        default:
          break;
      }
    },
    cellStyle(row, column, rowIndex, columnIndex) {
      //根据报警级别显示颜色
      if (row.column.label === "小计") {
        return "color:#C91C25;font-weight:600";
      }
    },
    countTime(points = 25, seconds = 59) {
      this.timer = setInterval(() => {
        seconds--;
        if (seconds < 0) {
          seconds = 59;
          points--;
        }
        if (points < 0) {
          this.$message.warning("订单支付超时");
          points = 0;
          seconds = 0;
          this.$router.go(-1);
          clearInterval(this.timer);
        }
        this.countdown = `${points}分${
          seconds < 10 ? `0${seconds}` : seconds
        }秒`;
      }, 1000);
    },
    fetch() {
      this.$store
        .dispatch("orderListDetail/getOrderDetail", {
          ...this.$route.query,
        })
        .then((result) => {
          result.countdown > 0 && this.countTime(result.countdown);
        });
    },
  },
  // 通过computed计算属性及时改变
};
</script>

<style lang="less" scoped>
.goods-img {
  width: 80px; /* no */
  height: 80px; /* no */
  border: none;
}
.clearFix() {
  *zoom: 1;
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}
.details {
  float: left;
  margin-left: 20px; /* no */
  width: 100%;
  border: 1px solid #eeeeee; /* no */
  background-color: #ffffff;
  .banner {
    width: 100%;
    height: 62px; /* no */
    background: #ffffff;
    border-bottom: 1px solid #eee; /* no */
    .line {
      width: 5px; /* no */
      height: 20px; /* no */
      background: #24a2aa;
      border-radius: 2px; /* no */
      margin: 21px 0 0 20px; /* no */
      float: left;
    }
    h2 {
      margin-left: 35px; /* no */
      font-size: 22px; /* no */
      line-height: 62px; /* no */
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }
  }
  .content {
    width: 100%;
    .process {
      width: 95.85%;
      height: 304px; /* no */
      border: 1px solid #eeeeee; /* no */
      margin: 2.13% 0 0 2.13%;
      .pay {
        float: left;
        width: 28.19%;
        height: 100%;
        border-right: 1px solid #eeeeee; /* no */
        .ordernum {
          text-align: center;
          margin-top: 20px; /* no */
          > span {
            font-size: 14px; /* no */
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
          }
        }
        .wait {
          text-align: center;
          margin-top: 49px; /* no */
          padding-bottom: 28px; /* no */
          > span {
            font-size: 24px; /* no */
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #ca1c26;
          }
        }
        .time {
          margin: 10px 0 30px; /* no */
          text-align: center;
          > span {
            font-size: 14px; /* no */
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
          }
          .icon {
            padding-left: 19px; /* no */
            background: url(/img/orderDetails/time.png) no-repeat no-repeat left;
            background-size: 16px; /* no */
          }
        }
        .button {
          button {
            margin: 10px auto 0; /* no */
            width: 112px; /* no */
            height: 36px; /* no */
            background: #f4f4f4;
            border: 1px solid #d4d4d4; /* no */
            border-radius: 4px; /* no */
            display: block;
            &:hover {
              background: #ffffff;
              border: 1px solid #ca1c26; /* no */
              border-radius: 2px; /* no */
            }
          }
        }
      }
      .step {
        float: right;
        width: 71.65%;
        ul {
          width: 100%;
          height: 120px; /* no */
          margin-top: 90px; /* no */
          li {
            width: 14.6%;
            height: 100%;
            float: left;
            display: block;
            margin-left: 4%;
            text-align: center;
            color: #aaaaaa;
            position: relative;
            &.noQx {
              width: 14%;
            }
            &::after {
              content: "";
              width: 47.2%;
              height: 40px; /* no */
              position: absolute;
              top: 21%;
              right: -50%;
              background: url(/img/orderDetails/left.png) no-repeat no-repeat;
            }
            &:last-child:after {
              content: "";
              display: none;
            }
            .icon {
              width: 58px; /* no */
              height: 58px; /* no */
              margin: 0 auto;
              border: 1px dashed #999999; /* no */
            }
            .cont {
              margin-top: 12px; /* no */
              > span {
                font-size: 12px; /* no */
                display: block;
                font-family: Microsoft YaHei;
                font-weight: 400;
              }
            }
            &.active {
              color: #333333;
            }
          }
          .have(@urlIcon; @urlActive;) {
            .icon {
              background: @urlIcon no-repeat top center;
            }
            &.active {
              color: rgb(36 162 170);
              .icon {
                border: 1px dashed rgb(36 162 170); /* no */
                background-image: @urlActive;
              }
            }
          }
          .have1 {
            .have(
              url("/img/orderDetails/uporder.png")
                ;url("/img/orderDetails/uporderA.png") ;
            );
          }
          .have2 {
            .have(
              url("/img/orderDetails/paysuccess.png")
                ;url("/img/orderDetails/paysuccessA.png") ;
            );
          }
          .have3 {
            .have(
              url("/img/orderDetails/release.png")
                ;url("/img/orderDetails/releaseA.png") ;
            );
          }
          .have4 {
            .have(
              url("/img/orderDetails/determine.png")
                ;url("/img/orderDetails/determineA.png") ;
            );
          }
          .have5 {
            .have(
              url("/img/orderDetails/over.png")
                ;url("/img/orderDetails/overA.png") ;
            );
          }
          .have6 {
            > .icon {
              font-size: 34px; /* no */
              line-height: 65px; /* no */
            }
            &.active {
              .icon {
                border: 1px dashed rgb(36 162 170); /* no */
              }
              color: rgb(46, 196, 205);
            }
          }
        }
      }
    }
    .info {
      width: 92.85%;
      border: 1px solid #eeeeee;
      margin: 2.13% 0 0 2.13%;
      overflow: hidden;
      padding: 20px 30px 80px; /* no */
      .person,
      .payInfo {
        float: left;
        width: 49.8%;
        height: 100%;
        border-right: 1px solid #eeeeee; /* no */
        > h4 {
          font-size: 14px; /* no */
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #484848;
        }
        > ul {
          > li {
            .clearFix();
            margin-top: 20px; /* no */
            display: block;
            > span {
              float: left;
              font-size: 14px; /* no */
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #666666;
            }
            .addres {
              width: 40%;
            }
          }
        }
      }
      .payInfo {
        float: left;
        width: 46%;
        margin-left: 30px; /* no */
        height: 100%;
      }
    }
    .goods {
      width: 95.85%;
      margin: 20px 0 50px 2.13%; /* no */
      .title {
        width: 100%;
        height: 44px; /* no */
        background: #f4f4f4;
        > span {
          display: block;
          float: left;
          width: 11%;
          height: 100%;
          line-height: 44px; /* no */
          font-size: 14px; /* no */
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          text-align: center;
          &:first-child {
            width: 39%;
            margin-left: 3%;
            text-align: left;
          }
        }
      }
      .goods-total {
        text-align: right;
        padding: 30px; /* no */
        font-family: SourceHanSansCN-Regular;
        border: 1px solid rgb(235 238 245); /* no */
        border-top: none;
        color: #666666;
        li {
          padding-bottom: 10px; /* no */
        }
        .goodsTotal-label {
          display: inline-block;
          width: 200px; /* no */
          text-align: right;
          font-size: 14px; /* no */
        }
        .goodsTotal-sfk {
          color: #ca1c26;
          .goodsTotal-num {
            font-size: 18px; /* no */
          }
        }
        .goodsTotal-num {
          display: inline-block;
          width: 200px; /* no */
          text-align: right;
        }
      }
    }
  }
}
</style>
