<template>
  <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
    <el-form ref="cancelOrderModalFrom" :model="cancelOrderModalFrom" label-width="80px">
      <el-form-item label="取消原因" prop="OrderCancleReasonId" :rules="[{ required: true, message: '请选择取消原因'}]">
        <el-select placeholder="请选择" v-model="cancelOrderModalFrom.OrderCancleReasonId" style="width: 100%;">
          <el-option v-for="item in cancleReasonSelectList" :key="item.id" :label="item.reason" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="原因描述" :rules="[{ required: true, message: '请输入原因描述'}]" prop="CancleReason">
        <el-input v-model="cancelOrderModalFrom.CancleReason" :rows="4" type="textarea" placeholder="请输入原因描述">
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" class="handleConfirm" @click="handleConfirm">确定取消</el-button>
      <el-button class="handleClose" @click="handleClose">暂不取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getCancleReasonSelectList } from "@/api/baseAPI";
export default {
  name: "partsOrderBtn",
  data() {
    return {
      cancelOrderModalFrom: {
        OrderCancleReasonId: "",
        CancleReason: "",
      },
      cancleReasonSelectList: [],
    };
  },
  props: ["modalType", "dialogVisible"],
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      getCancleReasonSelectList().then((res) => {
        if (res.success) {
          let { list } = res.result;
          this.cancleReasonSelectList = list;
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    resetFields() {
      this.$refs["cancelOrderModalFrom"].resetFields();
    },
    handleClose(done) {
      this.resetFields();
      this.$emit("handleClose", { done, modalType: this.modalType });
    },
    handleConfirm(done) {
      this.$refs["cancelOrderModalFrom"].validate((valid) => {
        if (valid) {
          this.$emit("handleConfirm", {
            form: this.cancelOrderModalFrom,
            modalType: this.modalType,
          });
          this.resetFields();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-footer {
  text-align: right;
  padding-right: 80px; /*no*/
  padding-bottom: 20px; /*no*/
  .handleConfirm {
    background: rgb(46, 196, 205);
    border-color: #dcdfe6;
    margin-right: 10px; /*no*/
  }
  /deep/.el-button:focus {
    background: #fff;
    border-color: #dcdfe6;
    color: #606266;
  }
  /deep/.el-button.handleClose:hover {
    border-color: #48d1cc;
    color: rgb(46, 196, 205);
  }
}
</style>
